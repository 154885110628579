import { IonContent, IonIcon, IonModal, IonNav, IonSearchbar, IonToolbar, isPlatform } from '@ionic/react';
import type { ComponentProps } from 'react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import styles from './FileUpload.module.scss';

interface FileModal {
  trigger: string;
}

const DUMMY_TAB_PAGE = (props: any) => {
  const { nav } = props as { nav: HTMLIonNavElement };
  return (
    <div>
      DUMMY_TAB_PAGE
    </div>
  );
};

const FileUpload: React.FC<FileModal> = (props) => {
  const isDesktop = isPlatform('desktop') ? 'desktop' : 'mobile';
  const nav = useRef<HTMLIonNavElement>(null);
  const modal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation();

  const didPresent = () => {
    if (nav.current) {
      nav.current.setRoot(DUMMY_TAB_PAGE, { nav: nav.current });
    }
  };
  const dismiss = () => {
    if (modal.current) {
      modal.current.dismiss();
    }
  };

  return (
    <IonModal
      ref={modal}
      trigger={props.trigger}
      onDidPresent={didPresent}
      backdropDismiss={true}
      className={styles[`file-upload-modal--${isDesktop}`]}
    >
      <BigUp.Modal.ModalHeader
        title={t('Selected files to upload')}
        end={{
          button: {
            title: t('Save'),
          },
          onClick: () => {
            console.log('Save selected files, clear selection and close modal');
          }
        }}
        start={{
          button: {
            title: t('Cancel'),
          },
          onClick: () => {
            dismiss();
            console.log('Clear selected files and close modal');
          }
        }}
      />
      <IonToolbar className='ion-no-padding' color={'light'}>
        <IonSearchbar mode={'ios'} />
      </IonToolbar>

      <IonContent style={{ '--background': 'var(--ion-color-light-shade)' }}>
        <IonNav ref={nav}></IonNav>
      </IonContent>
    </IonModal>
  );
};

export default FileUpload;
