import type { ActionPerformed, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { IonAlert, getPlatforms, isPlatform } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { networking } from './api/networking';
import { useAppSelector } from './hooks';
import i18n from './i18n';
import { setCountPollerInterval, setIsUpdatingCount, setShouldUpdateCount } from './reducers/authentication';
import storage from './storage';
import store from './store';

interface ContainerProps {
}

const NotificationListener: React.FC<ContainerProps> = (props) => {
  const [showNetworkErrorAlert, setShowNetworkErrorAlert] = useState<boolean>(false);
  const [allowNotifications, setAllowNotifications] = useState<boolean>(false);
  const shouldUpdateCount = useAppSelector(state => state.authentication.should_update_count);
  const countPollerInterval = useAppSelector(state => state.authentication.count_poller_interval);
  const isUpdatingCount = useAppSelector(state => state.authentication.is_updating_count);
  const { t } = useTranslation();
  const user = useAppSelector(state => state.authentication.user);
  const history = useHistory();

  const getUnreadCount = (forced = false) => {
    if (isUpdatingCount && !forced) {
      return;
    }
    if (typeof countPollerInterval !== 'undefined') {
      clearTimeout(countPollerInterval);
      store.dispatch(setCountPollerInterval(undefined));
    }
    store.dispatch(setIsUpdatingCount(true));

    // // Get the unread count
    // networking.get('/user/unread').then((response: GetUserUnreadCount) => {
    //   store.dispatch(setUnreadCount(response.data.count));
    // }).finally(() => {
    //   store.dispatch(setIsUpdatingCount(false));
    //   const pollerTimeout = setTimeout(() => getUnreadCount(), 7500);
    //   store.dispatch(setCountPollerInterval(pollerTimeout));
    // });
  };

  const requestPermission = () => {
    console.log('Push notification permissions requested');
    if (isPlatform('desktop') || isPlatform('mobileweb')) {
      return;
    }
    PushNotifications.requestPermissions()
      .then((permission) => {
        if (permission.receive === 'granted') {
          setUpNotifications();
        } else {
          setAllowNotifications(true);
        }
      });
  };

  const setUpNotifications = async () => {
    if (isPlatform('desktop') || isPlatform('mobileweb')) {
      return;
    }
    PushNotifications.addListener('registration', (token: Token) => {
      if (!user) {
        return;
      }
      storage.set('push_token', token.value);
      networking.post('/api/v1/me/device_tokens/register', {
        type: 'fcm',
        token: token.value
      })
        .then((response) => {
          console.error('RESPONSE: ', response);
        })
        .catch((error) => {
          console.error('ERROR: ', error);
        });
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('registrationError', error);
    });

    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log('pushNotificationReceived', notification);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      if (action.actionId === 'tap') {
        if (action.notification.data.click_action) {
          history.push(action.notification.data.click_action);
        }
      }
    });
  };

  useEffect(() => {
    setUpNotifications();
  });

  useEffect(() => {
    if (isPlatform('desktop') || isPlatform('mobileweb')) {
      return;
    }
    if (user) {
      PushNotifications.checkPermissions().then(function (status) {
        if (status.receive === 'prompt') {
          PushNotifications.requestPermissions().then(function (status) {
            if (status.receive !== 'granted') {
              console.error('User denied permissions!');
            } else {
              PushNotifications.register();
            }
          });
        } else if (status.receive !== 'granted') {
          console.error('User denied permissions!');
        } else {
          PushNotifications.register();
        }
      });
    }
    // checkNotifications();
    // getUnreadCount();
    return () => {
      if (typeof countPollerInterval !== 'undefined') {
        clearTimeout(countPollerInterval);
        store.dispatch(setCountPollerInterval(undefined));
      }
    };
  }, [user]);

  useEffect(() => {
    if (shouldUpdateCount) {
      getUnreadCount(true);
      store.dispatch(setShouldUpdateCount(false));
    }
  }, [shouldUpdateCount]);

  return (
    <React.Fragment>
      <IonAlert
        isOpen={showNetworkErrorAlert}
        onDidDismiss={() => setShowNetworkErrorAlert(false)}
        header={i18n.t('An error occured')}
        subHeader={i18n.t('Check your internet connection')}
        message={('This could be due to a network error. Please try again later.')}
        buttons={[t('OK')]}
      />
      <IonAlert
        isOpen={allowNotifications}
        header={i18n.t('Notifications')}
        message={i18n.t('In order to use all functionality, you need to allow notifications')}
        buttons={[
          {
            text: i18n.t('Cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => setAllowNotifications(false)
          },
          {
            text: i18n.t('Approve'),
            handler: () => requestPermission()
          }
        ]}
      />
    </React.Fragment>
  );
};

export default NotificationListener;
