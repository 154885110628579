import { Browser } from '@capacitor/browser';
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSkeletonText, useIonRouter
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { hammer } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './CreateAccountForm.module.scss';
import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';
import { ValidationBadge } from '../../UI/Inputs/ValidationBadge';
import ReturnChevronText from '../../UI/Return/ReturnChevronText';

// @todo: Replace with actual typing on client account.
interface CreateAccountFormType {
    package_id: string,
    organization_number: string,
    name: string,
    addresses: {
        line: string,
        line_2: string,
        postal_code: string,
        city: string,
        country_id: string
    }[]
}

// @todo: Move to typing package
interface PricingPackage {
    id: string;
    name: string;
}

const CreateAccountForm: React.FC = () => {
  const { t } = useTranslation();
  const methods = useForm<CreateAccountFormType>({
    defaultValues: {
      package_id: '',
      organization_number: '',
      name: '',
      addresses: [{
        line: '',
        line_2: '',
        postal_code: '',
        city: '',
        country_id: 'SE'
      }]
    }
  });
  const [packages, setPackages] = useState<PricingPackage[]>([]);
  const isFetchingPackages = useAppSelector((state) => state.loading.isLoading.fetchingAvailablePackages);
  const router = useIonRouter();
  const isCreatingAccount = useAppSelector((state) => state.loading.isLoading.clientAccountCreation);

  const handleAccountCreation: SubmitHandler<CreateAccountFormType> = (data) => {
    store.dispatch(setIsLoading({ name: 'clientAccountCreation', value: true }));
    networking.post('/api/v1/clients', data)
      .then(() => {
        router.push('/client-account/success');
      })
      .catch((err) => {
        if (networking.isAxiosError(err)) {
          toasters.error(t('Failed to submit client account request. Please contact our support.'));
        } else {
          toasters.error(t('Failed to submit client account request. Please contact our support.'));
        }
      })
      .finally(() => store.dispatch(setIsLoading({ name: 'clientAccountCreation', value: false })));
  };

  const openPricingPage = () => {
    Browser.open({ url: process.env.PRICING_PAGE_URL ?? 'https://bigup.se' });
  };

  const fetchAvailablePackages = () => {
    store.dispatch(setIsLoading({ name: 'fetchingAvailablePackages', value: true }));
    networking.get('/api/v1/packages')
      .then((res: E2U.V1.Response.Success<PricingPackage[]>) => {
        setPackages(res.data.data);
      })
      .catch(() => {
        toasters.error(t('Failed to fetch available packages. Contact our support to continue.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'fetchingAvailablePackages', value: false }));
      });
  };

  useEffect(() => {
    fetchAvailablePackages();
  }, []);

  return (
    <>
      <ReturnChevronText text={i18n.t('Return')} color={'tertiary'} fill={'clear'}/>
      <div className={'ion-no-padding ion-padding-horizontal ion-padding-bottom'}>
        {(isFetchingPackages || isCreatingAccount)
          ? <IonSkeletonText/>
          : <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleAccountCreation)}>
              <IonGrid>
                <IonRow>
                  <IonCol size={'12'}>
                    <IonRow className={'ion-align-items-center'}>
                      <IonCol size={'auto'}>
                        <IonIcon icon={hammer} size={'large'} color={'primary'}/>
                      </IonCol>
                      <IonCol>
                        <BigUp.Title
                          color={'primary'}>{t('Get started using BigUp')}</BigUp.Title>
                      </IonCol>
                    </IonRow>
                    <p className={'ion-no-margin ion-margin-bottom'}>{t('Fill out the details below and we\'ll reach out within one day.')}</p>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size={'12'}>
                    <BigUp.Label.Thick label={t('1. Select Package')}/>
                    <p className={'ion-no-margin ion-margin-bottom'}>
                      <span>{t('For more details about the package options and their features, please see our pricing page: ')}</span>
                      <u className={styles['CreateAccountForm-link']}
                        onClick={() => openPricingPage()}>{t('Open pricing page')}.</u>
                    </p>
                    <IonList className={'ion-margin-top'}>
                      <Controller
                        rules={{
                          required: t('Package is required.')
                        }}
                        render={({ field: { onChange, value } }) => <>
                          <IonRadioGroup
                            value={value}
                            onIonChange={(e) => onChange(e.target.value)}
                          >
                            {packages.map((p, i) => (
                              <IonItem key={i} className='ion-no-padding'>
                                <IonRadio value={p.id}>{p.name}</IonRadio>
                              </IonItem>
                            ))}
                          </IonRadioGroup>
                        </>}
                        name={'package_id'}
                      />
                      {methods.formState.errors.package_id && <div className='ion-no-margin'
                        style={{
                          height: 20,
                          paddingTop: 5,
                          display: 'flex'
                        }}>
                        <ValidationBadge>
                          {methods.formState.errors.package_id.message}
                        </ValidationBadge>
                      </div>}
                    </IonList>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className={'ion-no-margin ion-margin-top'}>
                    <BigUp.Label.Thick label={t('2. Enter Organisation Details')}/>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <BigUp.Input
                      register={'organization_number'}
                      label={t('Organisation number')}
                      placeholder={t('Enter your organisational number (10 digits)')}
                      validation={{
                        required: t('Organizational number is required.')
                      }}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <BigUp.Input
                      register={'name'}
                      label={t('Organisation name')}
                      placeholder={t('Enter the name of the organisation')}
                      validation={{
                        required: t('Organisation name is required.')
                      }}
                      autocomplete={'organization'}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className={'ion-no-margin ion-margin-top'}>
                    <BigUp.Label.Thick label={t('3. Enter Invoice Address')}/>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <BigUp.Input
                      register={'addresses.0.line'}
                      label={t('Address line')}
                      placeholder={t('Enter your invoice postal address')}
                      validation={{
                        required: t('Invoice address is required.')
                      }}
                      // @ts-expect-error This should work acc. to HTML standard.
                      autocomplete={'billing work address-line1'}
                      forceError={methods.formState.errors.addresses?.[0]?.line?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <BigUp.Input
                      register={'addresses.0.line_2'}
                      label={t('Extra line')}
                      placeholder={t('Optional second invoice address line.')}
                      validation={{}}
                      // @ts-expect-error This should work acc. to HTML standard.
                      autocomplete={'billing work address-line2'}
                      forceError={methods.formState.errors.addresses?.[0]?.line_2?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size={'auto'}>
                    <BigUp.Input
                      register={'addresses.0.postal_code'}
                      label={t('Postal code')}
                      placeholder={t('Enter postal code.')}
                      validation={{
                        required: t('Invoice address postal code is required.')
                      }}
                      // @ts-expect-error This should work acc. to HTML standard.
                      autocomplete={'billing work postal-code'}
                      forceError={methods.formState.errors.addresses?.[0]?.postal_code?.message}
                    />
                  </IonCol>
                  <IonCol>
                    <BigUp.Input
                      register={'addresses.0.city'}
                      label={t('City')}
                      placeholder={t('Invoice address city.')}
                      validation={{
                        required: t('Invoice address city is required.')
                      }}
                      // @ts-expect-error This should work acc. to HTML standard.
                      autocomplete={'billing work address-level1'}
                      forceError={methods.formState.errors.addresses?.[0]?.city?.message}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <BigUp.CountrySelect
                      default={'SE'}
                      register={'addresses.0.country_id'}
                      label={t('Country')}
                      placeholder={t('Invoice address country')}
                      validation={{
                        required: t('Invoice address country is required.')
                      }}
                      // @ts-expect-error This should work acc. to HTML standard.
                      autocomplete={'billing work country'}
                      forceError={methods.formState.errors.addresses?.[0]?.country_id?.message}
                      fetchCountries={true}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className={'ion-no-margin ion-margin-top'}>
                    <BigUp.Buttons.Regular title={t('Submit request')} type={'submit'}
                      color={'success'}/>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </FormProvider>
        }
      </div>
    </>
  );
};

export default CreateAccountForm;
